import React from 'react';
import ImgComponent from '../imgComponent';
import ClientLogo from './ClientBM';
import Title from './TitleContent';

const Diagram = () => {
    return (
        <section
            className="elementor-element elementor-element-92d239f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section exp-bm-section"
            data-id="92d239f"
            data-element_type="section"
            data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
        >
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                    <div
                        className="elementor-element elementor-element-2e1f0cf elementor-column elementor-col-100 elementor-top-column"
                        data-id="2e1f0cf"
                        data-element_type="column"
                        data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
                    >
                        <div className="elementor-column-wrap elementor-element-populated">
                            <div className="elementor-widget-wrap">
                                <div
                                    className="elementor-element elementor-element-14aaa15 elementor-widget elementor-widget-avante-gallery-grid"
                                    data-id="14aaa15"
                                    data-element_type="widget"
                                    data-settings="{&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
                                    data-widget_type="avante-gallery-grid.default"
                                >
                                    <div className="elementor-widget-container">
                                        <div className="avante-gallery-grid-content-wrapper layout-avante-one-cols">
                                            <div
                                                className="gallery-grid-item avante-one-cols hanif clients"
                                                data-delay={10}
                                                data-minwidth={769}
                                                data-scale-x={0}
                                                data-scale-y={0}
                                            >
                                                <ClientLogo />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Diagram;
